.content-parallax
{
    height: 100vh;
    width: 100%;
}

.text-content-parallax{
   
 padding: 5px 15px;
 transform:  translate(-20%, -30%);
 /* background: gray; */
 color: white;
 border-radius:20px;
 font-size: 50px; 
 text-align: center;
}

.claro{
    color: white;

}

@media (max-width: 576px) {
.text-content-parallax  {
 
 
  font-size: 35px !important; 
 
}
 
  /* .claro{
   
} */
}

.oscuro{
    /* color: rgb(31, 30, 30); */
    font-size: 50px;
    background: linear-gradient(to right, #4e4848, #181817, #ccccd3);
    /* background-image: linear-gradient(to  top, #217834, #3a8a32, #559d2e, #72af26, #91c018); */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
}


/* .text-content-parallax:hover{
    filter: blur(1px);
    } */

/* 
    .text-content-parallax:hover{
        
        background-image: linear-gradient(to  top, #217834, #3a8a32, #559d2e, #72af26, #91c018);
        transition: background 0.5s ease;
        }
        .text-content-parallax:hover{
            transform: scale(1.2);
            transition: transform 0.5s ease;
            } */
/* 

    .text-content-parallax{
        animation: move 2s ease infinite alternate;
        }
        
        @keyframes move {
        from { transform: translateX(-20px); }
        to { transform: translateX(20px); }
        }

         */

         .text-content-parallax {
            font-size: 50px;
            text-align: center;
            position: relative;
          }
          
          .text-content-parallax::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: radial-gradient(circle at 30% 107%, rgba(113, 201, 186, 0.5) 0%, transparent 60%);
            animation: glow 1.5s ease-in-out infinite;
            opacity: 0;
          }
          
          @keyframes glow {
            0% {
              transform: scale(1);
              opacity: 0.5;
            }
            50% {
              transform: scale(2);
              opacity: 0;
            }
            100% {
              transform: scale(1);
              opacity: 0.5;
            }
          }
          .text-content-parallax{
            animation: move 2s ease infinite alternate;
            }
            
            @keyframes move {
            from { transform: translateX(-20px); }
            to { transform: translateX(20px); }
            }
    

    

 