 

 
.calendario-content {
 
    background-image: radial-gradient(circle, #217834, #3a8a32, #559d2e, #72af26, #91c018);
    background: linear-gradient(-45deg, #ee7752, #559d2e, #23a6d5, #23d5ab);
    /* background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab); */
        background-size: 400% 400%;
        animation: gradient 15s ease infinite;
      }
      
      @keyframes gradient {
        0% {
          background-position: 0% 50%;
        }
        50% {
          background-position: 100% 50%;
        }
        100% {
          background-position: 0% 50%;
        }
      }