.gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.gallery-item {
  position: relative;
  width: 80%;
  height: 240px;
  margin: 15px;
  cursor: pointer;
  transform-style: preserve-3d;
  transition: transform 0.5s ease;
}

.gallery-item.active {
  transform: rotateY(0deg) translateZ(100px);
}

.gallery-item-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transform: translateZ(-100px);
  transition: transform 0.5s ease;
}

.gallery-item.active .gallery-item-image {
  transform: translateZ(0);
}

.gallery-item-description {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: 1.2rem;
  transform: translateZ(100px);
  transition: transform 0.5s ease;
}

.gallery-item.active .gallery-item-description {
  transform: translateZ(0);
}





/* MODAL */

  .modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
 
  pointer-events: none;
  transition: all 0.3s ease-in-out;
}

.modal-content {
  position: relative;
  width: 80%;
  max-width: 1400px;
  background-color: #fff;
  padding: 20px;
  text-align: center;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  transform: translateY(-50px);
  transition: all 0.3s ease-in-out;


}

.modal-content img {
  width: 100%;
  height: auto;
  object-fit: contain;
  max-height: 70vh;
}

.modal-content p {
  margin-top: 10px;
  font-size: 1.2rem;
  color: #333;
}

/* .modal-content button {
  position: absolute;
 
  background-color: transparent;
  border: none;
  font-size: 2.2rem;
  color: #462374;
  cursor: pointer;
}

.modal-content button:hover {
  color: #ddd;
}   */
.modal-content button {
  position: absolute;
 
  transform: translateY(-10%);
  background-color: transparent;
  border: none;
  font-size: 2.2rem;
  color: #000000;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 10px;
  border: 2px solid #fff;
  transition: all 0.2s ease;
  background-color: #0c0c0c17;
}

.modal-content button:hover {
  background-color: #fff;
  color: #143a1f;
}
 
 
 

.modal-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
 
}

.prev-btn, .next-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 30px;
  color: #333;
  outline: none;
  top: 50%;
  transition: transform 0.3s ease;

}

.prev-btn {
  left: 2%;
}
  .next-btn {
  right: 2%;
}
 
.prev-btn:hover, .next-btn:hover {
  transform: scale(1.2);
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 30px;
  
  outline: none;
  transition: transform 0.3s ease;
}

.close-btn:hover {
  transform: rotate(45deg);
}