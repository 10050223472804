.slider {
    width: 80%;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
  }
  
  .slider img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }
  
  .slider img.active {
    opacity: 1;
  }
  